import React from "react"

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const ContactForm = props => {
  return (
    <Form name="Contact Form" method="POST" data-netlify="true" action="/danke">
      <input type="hidden" name="form-name" value="Contact Form" />
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" required />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>E-Mail</Form.Label>
        <Form.Control type="email" name="email" required />
      </Form.Group>
      <Form.Group controlId="fromPhone">
        <Form.Label>Telefon</Form.Label>
        <Form.Control type="phone" name="phone" required />
      </Form.Group>
      <Form.Group controlId="fromMessage">
        <Form.Label>Nachricht</Form.Label>
        <Form.Control as="textarea" rows="3" name="message" required />
      </Form.Group>
      <Button variant="primary" type="submit" block>
        Abschicken
      </Button>
    </Form>
  )
}

export default ContactForm
