import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Page from "../templates"
import SEO from "../templates/seo"
import ContactForm from "../components/ContactForm"

const Kontakt = () => {
  return (
    <Page>
      <SEO title="Kontakt" />
      <h1 className="text-primary">Kontakt</h1>
      <p>Schreib mir eine Nachricht!</p>
      <Row className="py-3">
        <Col xs="12" md="8" className="px-md-3">
          <div className="pl-md-3 pr-md-5">
            <ContactForm />
          </div>
        </Col>
        <Col xs="12" md="4" className="pl-md-2 pl-lg-5 mt-5 mt-md-2">
          <div className="pl-md-3 text-center text-md-left">
            <h5>Anne Ewers</h5>
            <p>
              Bachstraße 1<br />
              33098 Paderborn
            </p>
            <p>
              <a href="mailto:anne.ewers@kunst-werk.art">
                anne.ewers@kunst-werk.art
              </a>
              <br />
              T: 05251 122430
            </p>
          </div>
        </Col>
      </Row>
    </Page>
  )
}

export default Kontakt
